import campaign from './campaigns/instock-models'
import indoorSolutions from './static-content/indoorSolutions'
import altText from './static-content/altText'

const hero = campaign()

const sections = [
    {
        id: 'features',
        class: 'two-column-img-right',
        hasNavAnchor: true,
        title: 'Better environments, inside and out',
        imgSrc: '/images/lifestyle/large_why_buy_split.webp',
        imgAlt: 'image split between interior wall-mounted Mistubishi Electric mini-split unit, and exterior Mistubishi Electric heat pump unit',
        detailsText: 'Our ductless mini-splits are all-electric, all-climate solutions for one room, two rooms, or a whole home.',
        iconList: {
            title: 'Benefits beyond savings',
            items: [
                { text: 'Ductless and/or ducted configuration' },
                { text: 'All-electric, no greenhouse gas emissions' },
                { text: 'Cold climate ready' },
                { text: 'More energy-efficient' },
                { text: 'Industry-leading warranty' },
            ],
        },
    },
    indoorSolutions({ hasNavAnchor: true }),
    {
        id: 'personalized',
        class: 'two-column-img-left',
        title: 'Personalized comfort in every room',
        imgSrc: '/images/house_of_comfort_with_people_temp.webp',
        imgAlt: 'image split between interior wall-mounted Mistubishi Electric mini-split unit, and exterior Mistubishi Electric heat pump unit',
        detailsText:
            'One multi-zone outdoor unit connects up to eight indoor units and provides personalized comfort in multiple spaces. This eliminates problem hot and cold spots and only uses energy in the zones that need it.',
        videoSrc: 'oETVJ7Nx9g0',
        ctaText: 'Watch how it works',
    },
    {
        id: 'hire-with-confidence',
        class: 'three-column-cards-w-image',
        title: 'Hire with confidence',
        contentBoxes: [
            {
                iconSrc: 'warranty',
                iconAlt: `${altText} 12 and 12 warranty icon`,
                blurb: 'Extended warranty',
                detailsText: 'We extend our factory warranty to 12 years for customers who choose an independent Diamond Contractor to install their system.',
            },
            {
                iconSrc: 'handshake',
                iconAlt: `${altText} two hands shaking`,
                blurb: 'Advanced training',
                detailsText: `These independent contractors have received advanced training directly from Mitsubishi Electric Trane HVAC US and receive ongoing product, service, and installation updates and training.`,
            },
            {
                iconSrc: 'power-bill-invoice',
                iconAlt: `${altText} payment and financing options`,
                blurb: 'Financing options',
                detailsText:
                    'Mitsubishi Electric and Synchrony Financial make it easier to afford the latest, most efficient heating and cooling systems. Contractor financing options vary.',
            },
        ],
        backgroundCol: 'blue',
    },
]

const header = {
    pageTitle: 'Maximize your 2024 tax savings potential. Heat pump installations could qualify for $2,000 tax credit.',
    pageDesc:
        "Stay comfortable year round with Mitsubishi Electric's single-zone and Hyper-Heating INVERTER® heat pump systems, where exceptional comfort meets energy efficiency. Enter your zip code now to unlock local incentives!",
    pageImg: hero.backgroundSrc,
    phone: '',
    ctaText: 'Get an in-home consultation today!',
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }

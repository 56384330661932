import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '11/1/2024'
    const end = '1/31/2025'
    const amount = 0

    const data = {
        // defaults
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000002FARxAAO',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/interior/service-4.webp',
        defaultTitle: defaults.title,
        defaultBlurb: defaults.blurb,
        defaultDetails: defaults.detailsText,
        defaultFootnote: defaults.footnote,
        defaultWhatsNext: defaults.whatsNext,
        utilityProduct: defaults.utilityProduct,
        submitFormBtnText: null,
        // ME_Residential_SBU_Fall_2024_In_Stock_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        staticCampaign: true,
        campaignId: '701Pd00000JBnDoIAL',
        campaignCtaText: 'Get an in-home consultation today!',
        campaignWhatsNext: '',
        campaignImage: '/images/lifestyle/interior/service-4.webp',
        campaignHero: 'rebate',
        campaignTitle: 'Remaining stock is ready to install',
        campaignBlurb: '',
        campaignDetails: 'Maximize your 2024 tax savings potential. Heat pump installations could qualify for $2,000 tax credit. ',
        campaignFootnote: ``,
        showFootnote: true,
        hideContractorNumSelector: true,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}

// convert booleans to "Yes" / "No" and vice versa
export const booleanToYesNo = (value) => {
    switch (value) {
        case true:
            return 'Yes'
        case false:
            return 'No'
        case 'true':
            return 'Yes'
        case 'false':
            return 'No'
        case 'Yes':
            return true
        case 'No':
            return false
        default:
            return value
    }
}

export const createTitle = (title) => title.replace(/_/g, ' ')

export const createKeyname = (string) => string.replace(/\(|\)/g, '').replace(/[-\s]/g, '_').replace(/1/g, 'one').replace(/4/g, 'four').toLowerCase()

export const createSlug = (string) => string.replace(/\(|\)/g, '').replace(/[-\s]/g, '-').toLowerCase()

export const findKeyIndex = (address, firstKey, secondKey, thirdKey) => {
    let key
    address.forEach((addressComponent) => {
        if (addressComponent.types.includes(firstKey)) {
            key = firstKey
            return
        }
        if (addressComponent.types.includes(secondKey)) {
            key = secondKey
            return
        }
        if (addressComponent.types.includes(thirdKey)) {
            key = thirdKey
        }
    })
    return address.findIndex((x) => x.types.includes(key))
}

export const parseGeoData = (geoData) => {
    const location = geoData.results[0].address_components
    console.log('location:', location)
    const parsedGeoData = {
        // geoCounty: location[findKeyIndex(location, 'administrative_area_level_2')].long_name,
        geoCity: location[findKeyIndex(location, 'locality', 'neighborhood')].long_name,
        geoZip: location[findKeyIndex(location, 'postal_code')].long_name,
        geoState: location[findKeyIndex(location, 'administrative_area_level_1')].long_name,
        geoStateCode: location[findKeyIndex(location, 'administrative_area_level_1')].short_name,
        geoCountry: location[findKeyIndex(location, 'country')].long_name,
    }
    return parsedGeoData
}

export const handleValue = (key, value) => {
    let unit
    if (value === -1000) {
        return 'n/a'
    }
    switch (key) {
        case 'capacity':
            unit = 'k BTU/H'
            break
        case 'max_height_diff_ft':
        case 'max_pipe_length_ft':
            unit = 'ft'
            break
        case 'cooling_min':
        case 'cooling_max':
        case 'cooling_thermal_lockout':
        case 'cooling_restart':
        case 'heating_min':
        case 'heating_max':
        case 'heating_thermal_lockout':
        case 'heating_restart':
            unit = '° F'
            break
        default:
            unit = ''
    }
    return value + unit
}

export const formatPhone = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value

    // remove country code and/or "1" if present
    if (value.startsWith('+') || value.startsWith('1')) {
        value = value.slice(1)
    }

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length
    if (!previousValue || value.length > previousValue.length) {
        // returns: "x", "xx", "xxx"
        if (cvLength < 4) return currentValue
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
    }
}

export const formatZip = (value) => {
    // return nothing if no value
    if (!value) return value
    const formattedValue = value.replace(/\D/g, '').slice(0, 5)
    return formattedValue
}

export const formatInteger = (value) => {
    if (!value) return value
    const regex = /\d/g
    const matchedDigits = value.match(regex)
    return matchedDigits ? matchedDigits.join('') : ''
}

export const formatHouseholdSize = (value) => {
    if (!value) return value
    const regex = /[1-8]/g
    const matchedDigits = value.match(regex)
    return matchedDigits ? matchedDigits.join('') : ''
}

export const numWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const log = (data1, data2) => console.log('--->', data1, data2 ? data2 : '')

export const stringToNum = (string) => (Number(string) || string === '0' ? Number(string) : string)

export const getLogoSrc = (fileName) => {
    let logoFolder = 'black'
    const userDarkModeIsOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    if (userDarkModeIsOn) logoFolder = 'white'
    return `/images/logos/${logoFolder}/${fileName}`
}

export const debug = (data) => {
    console.log(`--------------------`)
    console.log('DATA ---->', data)
    console.log(`--------------------`)
}

export const handleDetail = (detail) => {
    detail = detail.trim()
    const lastChar = detail.charAt(detail.length - 1)
    lastChar !== '.' && (detail = `${detail}.`)
    return detail
}
export const parseSymbols = (string) => {
    const parser = new DOMParser()
    return parser.parseFromString(string, 'text/html').body.textContent
}

import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import LoadingSpinner from './modular/LoadingSpinner'
import InputText from './modular/InputText'
import validateField from '../lib/validateField'
import Button from './modular/Button'
import { formatPhone, formatZip } from '../lib/helpers'
import { submitFormData } from '../lib/queries'
import ThankYou from './ThankYouWebinar'
import conversions from '../lib/conversions'

export default function LeadFormWebinar({ content, formClass }) {
    const registrationClosed = true
    const [leadFormSubmitted, setLeadFormSubmitted] = useState(false)

    /* ---------------- FORM DATA ---------------- */
    const [formData, setFormData] = useState({
        first_name: { value: '' },
        last_name: { value: '' },
        phone: { value: '' },
        email: { value: '' },
        company: { value: '' },
        zip: { value: '' },
        '00N3100000GpFax': { value: '', valid: true },
        '00N3100000GpFb4': { value: '', valid: true },
    })
    const [formIsValid, setFormIsValid] = useState(false)
    const [thankYouHeadline, setThankYouHeadline] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const tokenField = useRef()

    const GTMTrigger = content.GTMTrigger ? content.GTMTrigger : 'bottom_form'

    const currentPage = window.location.pathname.substring(1)

    /* ---------------- FIELD VALUES HANDLER ---------------- */
    const handleChange = (e) => {
        const field = e.target
        let { name, type, value, required } = field
        let checked

        if (name === 'phone' && value) {
            value = formatPhone(value)
        }

        if (name === 'zip' && value) {
            value = formatZip(value)
        }

        if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
            checked = e.target.checked
        }

        const isValid = validateField(type, name, required, value)
        // console.log('changed:', name, value, 'valid:', isValid)

        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: {
                    value: type === 'checkbox' ? checked : value,
                    valid: isValid,
                },
            }
        })
    }

    useEffect(() => {
        const allFieldsValid = Object.keys(formData).every((key) => formData[key].valid)
        setFormIsValid(allFieldsValid)
    }, [formData])

    /* ---------------- LOCAL STORAGE HANDLER ---------------- */
    const handleStorageReset = () => {
        console.log(`--> lead form resetting `)
        // handle conversion and storage
        conversions('lead', null, true)
        setThankYouHeadline('Form reset, now reload page')
    }

    useEffect(() => {
        // handle local storage items and thank-you
        const userName = localStorage.getItem('userName')
        const isSubmitted = localStorage.getItem(`${currentPage}_leadFormSubmitted`)
        const email = localStorage.getItem('email')

        if (email && (!formData.email || formData.email.value !== email)) {
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    email: {
                        value: email,
                        valid: true,
                    },
                }
            })
        }

        isSubmitted === 'true' && setLeadFormSubmitted(true)

        if (userName && (!formData.first_name || formData.first_name.value !== userName)) {
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    first_name: {
                        value: userName,
                        valid: true,
                    },
                }
            })
        }
    }, [formData, currentPage])

    /* ---------------- UTM PARAMETERS ---------------- */
    const currentUrl = new URL(document.location)
    const params = currentUrl.searchParams
    const newCampaignId = params.get('campaign_id')
    const newLeadSource = params.get('utm_source')
    const newSourceLeadId = params.get('source_lead_id')

    /* ---------------- RECAPTCHA + CALLBACKS ---------------- */
    const productForm = useRef() // parent <form>
    const submitBtn = useRef() // submit button

    /* ---------------- SUBMIT FORM VIA FETCH API ---------------- */
    const handleFormSubmit = (e) => {
        e.preventDefault()

        formIsValid &&
            window.grecaptcha.ready(() => {
                submitBtn.current.setAttribute('disabled', true)
                window.grecaptcha.execute('6LfByKkaAAAAAOyd9o6yhtx5vH7ocTGLzihvuJZQ', { action: 'lead_form_submit' }).then((token) => {
                    tokenField.current.value = token
                    setIsSubmitting(true)

                    // reconstruct form data
                    const data = new FormData(e.target)
                    data.append('CampaignID', newCampaignId ? newCampaignId : content.leadCampaignId)
                    data.append('LeadSource', newLeadSource ? newLeadSource : content.leadSource ? content.leadSource : 'Landing Page')
                    newSourceLeadId && data.append('SourceLeadId', newSourceLeadId)
                    data.append('00N3q00000GUtxs', true)
                    data.append('00N3q00000GUpoS', 'Lead')
                    data.append('country', 'United States')
                    data.append('recordType', '012i0000000xpJ5')

                    // submit form data
                    const response = async () => await submitFormData(data, true)

                    if (response()) {
                        // handle google Enhanced Conversions
                        conversions(
                            'lead',
                            {
                                name: formData.first_name.value,
                                email: formData.email.value,
                                zip: formData.zip.value,
                            },
                            null,
                        )
                        // append URL with "thank-you" param
                        const params = Object.fromEntries(searchParams)

                        // Add the new parameter.
                        params['thank-you'] = true

                        // Set the search parameters to the new object.
                        setSearchParams(params)

                        // replace form with thank you message
                        setThankYouHeadline(`Thank you, ${formData.first_name.value}!`)
                        setLeadFormSubmitted(true)
                        setIsSubmitting(false)
                    }
                })
            })
    }

    if (isSubmitting)
        return (
            <div className={formClass}>
                <LoadingSpinner loadingMessage={'Getting you registered...'} />
            </div>
        )

    if (registrationClosed)
        return (
            <ThankYou
                headline="Registration is now closed"
                thankYou="If you missed the opportunity to register and attend this webinar, but are still interested, please call us at (855) 897-9542 to inquire about future webinars."
                className="glass-panel"
            />
        )

    if (leadFormSubmitted)
        return (
            <ThankYou
                onClick={handleStorageReset}
                headline={thankYouHeadline}
                firstName={formData.first_name.value}
                userEmail={formData.email.value}
                thankYou={content.thankYou}
                className="glass-panel"
                showResetButton={content.showResetButton}
            />
        )

    return (
        <form
            onSubmit={(e) => handleFormSubmit(e)}
            action={GTMTrigger}
            id="form"
            className="standard-form-wrapper glass-panel"
            autoComplete="on"
            ref={productForm}
        >
            {/* recaptcha token field - GTM triggers submit only if value is NOT 'token' */}
            <input type="hidden" id="token" name="token" value="token" ref={tokenField} />

            <h2 className={formClass && formClass.includes('glass') ? 'h4' : undefined} style={{ textAlign: 'center', gridArea: 'unset', marginBottom: '0' }}>
                {content.formTitle ? content.formTitle : 'Request a Consultation'}
            </h2>
            <p className="form-instructions" style={{ maxWidth: '1000px', textAlign: 'center', margin: 'auto' }}>
                {content.formInstructions}
            </p>
            <div className="contact-info-wrapper">
                <InputText
                    id="first-name"
                    name="first_name"
                    label="First Name"
                    type="text"
                    placeholder="given name"
                    value={formData.first_name.value}
                    onChange={handleChange}
                    isValid={formData.first_name.valid}
                    required
                />

                <InputText
                    id="last-name"
                    name="last_name"
                    label="Last Name"
                    type="text"
                    placeholder="surname"
                    value={formData.last_name.value}
                    onChange={handleChange}
                    isValid={formData.last_name.valid}
                    required
                />

                <InputText
                    id="lead-email"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="name@domain.com"
                    maxLength="42"
                    value={formData.email.value}
                    onChange={handleChange}
                    isValid={formData.email.valid}
                    required
                />

                <InputText
                    id="phone"
                    name="phone"
                    label="Phone No."
                    type="tel"
                    placeholder="10-digit, U.S. numbers only"
                    value={formData.phone.value}
                    onChange={handleChange}
                    isValid={formData.phone.valid}
                    required
                />

                <InputText
                    id="company"
                    name="company"
                    label="Company Name"
                    type="text"
                    placeholder=""
                    value={formData.company.value}
                    onChange={handleChange}
                    isValid={formData.company.valid}
                    required
                />
                <InputText
                    id="zip"
                    name="zip"
                    label="Zip Code"
                    type="text"
                    maxLength="5"
                    placeholder="U.S. Only"
                    onChange={handleChange}
                    value={formData.zip.value}
                    isValid={formData.zip.valid}
                    errorMsg="Invalid Zip Code"
                    style={{ display: 'block' }}
                    required
                />
            </div>
            <div style={{ marginTop: 'var(--spc-lg-h)' }}>
                <InputText
                    id="00N3100000GpFax"
                    name="00N3100000GpFax"
                    label="If you have more immediate HVAC needs and would like to be contacted before the webinar, please provide details here."
                    textArea
                    rows="3"
                    placeholder="Please add any additional information here"
                    onChange={handleChange}
                    value={formData['00N3100000GpFax'].value}
                    isValid={formData['00N3100000GpFax'].valid}
                />

                <div className="final-form-checkbox-wrapper">
                    <div id="communications-wrapper">
                        <input id="ReceiveEmailCommunication" name="00N3100000GpFb4" type="checkbox" value={true} onChange={handleChange} />
                        <label className="visible" htmlFor="ReceiveEmailCommunication">
                            I would like to receive email and communications from Mitsubishi Electric Trane HVAC in the future
                        </label>
                    </div>
                    <Button ref={submitBtn} className="g-recaptcha" type="submit" id="bu-form-submit" text="Submit Form" disabled={!formIsValid} />
                </div>
            </div>

            <p className="footnote" style={{ margin: '0' }}>
                *Asterisk Denotes a Required Field
            </p>
        </form>
    )
}

import altText from './static-content/altText'
import hybridVrf from './static-content/hybridVrf'
import heat2O from './static-content/heat2O'
import innovativeProducts from './static-content/innovative-products'
import downloadIra from './static-content/download-ira'

const vertical = 'Hospitality'
const mainImg = '/images/hospitality-hero.webp'

const hero = {
    class: 'two-column-img-left dark-mode',
    backgroundSrc: mainImg,
    title: `HVAC Solutions For ${vertical} Applications`,
    // blurb: `Mitsubishi Electric's VRF HVAC Systems Deliver`,
    detailsText:
        "Quiet... Comfort... Clean Air. A better guest experience. Mitsubishi Electric's modular VRF technology features whisper-quiet in-room units, precise temperature control, and infinite scalability, making these systems a superior choice for hotels and resorts.",
}

const sideBar = [
    {
        vertical: vertical,
        bu: 'wbu',
        imgSrc: '/images/tom_varga_headshot.webp',
        firstName: 'Tom',
        lastName: 'Varga',
        jobTitle: 'Sr. Manager, Hospitality Vertical Market',
        phone: '(484) 885-0710',
        email: 'tvarga@hvac.mea.com',
        linkedIn: 'https://www.linkedin.com/in/tom-varga-8aaa8010/',
    },
]

const sections = [
    {
        id: 'feature',
        class: 'two-column-img-right',
        title: 'Featured',
        blurb: '"BALANCING ACT"',
        detailsText:
            'The November, 2024 issue of Hotel Business magazine featured a roundtable of top hotel executives. Read about their strategies for cutting costs without sacrificing quality.',
        imgSrc: '/images/hotel-business-magazine-november-2024-issue-cover.webp',
        imgAlt: `${altText} cover of november 2024 issue of hotel business magazine showing top hotel executives gathered for a roundtable discussion`,
        ctaText: 'Read online',
        ctaUrl: 'https://togo.hotelbusiness.com/article/hb-roundtable-balancing-act-top-executives-discuss-how-to-cut-costs-without-sacrificing-quality/',
        backgroundCol: 'green',
    },
    {
        id: 'Why VRF?',
        class: 'one-column-list',
        hasNavAnchor: true,
        title: `Why Use VRF In ${vertical}?`,
        listItems: [
            {
                iconId: 'city-multi',
                title: 'Proven Technology',
                description:
                    "Mitsubishi Electric's VRF technologies, employed globally for decades, have consistently demonstrated a reduction in overall operating costs compared to traditional heating and air conditioning systems.",
            },
            {
                iconId: 'service',
                title: 'Ease of Maintenance',
                description:
                    'The simplicity and convenience offered by CITY MULTI™ VRF systems mean lower maintainance for Facility Managers, improving overall operational efficiency and contributing to smoother, more effective facility management.',
            },
            {
                iconId: 'thermostat',
                title: 'Precision Control',
                description:
                    'Hoteliers are able to limit heating and cooling to only the portions of the hotel that are being used – avoiding the cost of maintaining temperatures across the entire building.',
            },
            {
                iconId: 'sustainable',
                title: 'Minimal Noise',
                description: 'VRF systems are extremely quiet… allowing your guests a relaxing stay and a refreshing night’s sleep.',
            },
        ],
        backgroundCol: 'gray',
    },
    {
        id: 'Testimonials',
        class: 'one-column-info no-padding-bottom',
        hasNavAnchor: true,
        title: `What Other Leaders in the Hospitality Industry Are Saying`,
        // blurb: `As the leader in VRF technology, we continue to advance technologies that reduce waste output and promote sustainable resource cycles, while increasing energy efficiency and eliminating dependence on fossil fuels.`
    },
    {
        id: 'Quote 1',
        class: 'two-column-img-left no-padding-bottom',
        // title: '"Nothing but compliments"',
        blurb: '"We had nothing but compliments"',
        detailsText:
            'Carl Hren, Senior Vice President at Concord Hospitality, talks about his experience working with the Mitsubishi Electric team and the benefits of using our VRF systems on his Autograph Collection Hotel project.',
        imgSrc: '/images/Quote 1-CarlHren-poster.webp',
        imgAlt: `${altText} poster for carl hren video clip`,
        ctaText: 'Play Clip',
        videoSrc: 'XHMI12klgj8',
        backgroundCol: 'transparent',
    },
    {
        id: 'Quote 2',
        class: 'two-column-img-left',
        // title: 'Testimonial - ',
        blurb: '"It just makes everything a lot easier"',
        detailsText:
            "Garry Vermaas, CEO and Principal at Base4, shares how Mitsubishi Electric's support and guidance on his hotel project was invaluable for his team and unique in the HVAC industry.",
        imgSrc: '/images/Quote 2-GarryVermaas-poster.webp',
        imgAlt: `${altText} poster for Garry Vermaas video clip`,
        ctaText: 'Play Clip',
        videoSrc: 'yiIQFXb09SI',
        backgroundCol: 'transparent',
    },
    {
        id: 'Projects',
        class: 'mapped-projects',
        hasNavAnchor: true,
        title: `${vertical} Projects By Region`,
        vertical: vertical,
        projects: `${vertical.toLowerCase()}-projects`,
    },
    innovativeProducts({}),
    hybridVrf({}),
    heat2O({}),
    {
        id: 'Case Studies',
        class: 'three-column-cards-w-image',
        hasNavAnchor: true,
        title: 'Case Studies',
        detailsText: `Review some of our most recent case studies to see how Mitsubishi Electric HVAC products are benefitting building owners, managers, and guests in the ${vertical} industry.`,
        contentBoxes: [
            {
                title: '800 N High St',
                blurb: 'Columbus, OH',
                detailsText: `"With the modular nature of the VRF system, if one of the modules happens to need service, we still have the vast majority of our units up and running. It gives us that strategic advantage over some of our other competitors"`,
                quoteSource: 'Nelson Yoder, RA, Principal at Crawford Hoying Development',
                imgSrc: '/images/hero_moxy.webp',
                imgAlt: `${altText} hotel project building exterior`,
                // ctaText: `Download Case Study`,
                // downloadUrl: '/mehvac_800northhighstreetcase_study_0.pdf'
            },
            // {
            // 	title: 'The Allison Inn and Spa',
            // 	blurb: 'Newberg, OR',
            // 	detailsText: `"The outdoor units are almost silent, take up very little space and are good looking as well. After a year-and-a-half managing the Allison’s Mitsubishi Electric installation, I can honestly say I never want to work with any other system ever again."`,
            // quoteSource: 'Sean McClellan, Chief Engineer at Allison Inn and Spa.',
            // 	imgSrc: '/images/allison-inn-spa-room.webp',
            // 	imgAlt: `${altText} hotel project room interior`,
            // 	// ctaText: `Download Case Study`,
            // 	// downloadUrl: '/allison-inn-spa.pdf'
            // },
            // {
            // 	title: 'Olas Verdes Hotel',
            // 	blurb: 'Province of Guanacaste, Nosara, Costa Rica',
            // 	detailsText: '"In this industry, you always get complaints about room temperature, but since opening... guests actually tell us how much they love the Mitsubishi Electric units!"',
            // quoteSource: 'Luis Pardo, General Manager, Olas Verdes Hotel',
            // 	imgSrc: '/images/hero_olasverdes.webp',
            // 	imgAlt: `${altText} hotel project building exterior`,
            // 	// ctaText: `Download Case Study`,
            // 	// downloadUrl: '/olasverdeshotel_case_study.pdf'
            // },
            {
                title: 'NoMad LA Hotel',
                blurb: 'Los Angeles, CA',
                detailsText:
                    '"The truth is, Mitsubishi Electric is a far superior product than its industry competitors. And to that point, we have not heard one single complaint from engineering or hotel management about the system."',
                quoteSource: 'Joseph Cervantes, Sr. Executive VP of Operations, R.D. Olson',
                imgSrc: '/images/hero_nomadhotel.webp',
                imgAlt: `${altText} hotel project building exterior`,
                // ctaText: `Download Case Study`,
                // downloadUrl: '/nomad_hotel_case_study.pdf'
            },
            {
                title: 'Hotel MARCEL',
                blurb: 'New Haven, CT',
                detailsText: `"VRF technology solved a big problem by being able to simultaneously heat and cool different spaces in a building. [Heat2O] handles all the domestic hot water needs... [and] enabled us to be one of the first all-electric hotel buildings in the U.S."`,
                quoteSource: 'Bruce R. Becker, Hotel Owner',
                imgSrc: '/images/hotel-marcel.webp',
                imgAlt: `${altText} hotel project building exterior`,
                // ctaText: `Download Case Study`,
                // downloadUrl: '/nomad_hotel_case_study.pdf'
            },
            // {
            // 	title: 'AC Marriott Bridge Park',
            // 	blurb: 'Dublin, OH',
            // 	detailsText: '"I can just ‘jump’ into a room from the interface and 75 percent of the time I can fix the issue right in the Diamond Controls program... rather than having to bug the guest, go to their room and start pulling equipment apart."',
            // 	quoteSource: 'AJ Strzelecki, Facility Engineer, Shaner Hotel Group',
            // 	imgSrc: '/images/hero_acmarriott.webp',
            // 	imgAlt: `${altText} hotel project building exterior`,
            // 	// ctaText: `Download Case Study`,
            // 	// downloadUrl: '/mehvac_ac_marriott_case_study.pdf'
            // },
            // {
            // 	title: 'Twilight River Cruise',
            // 	blurb: 'LeClaire, Iowa',
            // 	detailsText: `"A lot of people ride our boat because of how it looks on the inside. Mitsubishi Electric was the only manufacturer with an indoor unit, a ceiling cassette, that could fit between our 16-inch joists."`,
            // 	imgSrc: '/images/twilight_hero.webp',
            // 	imgAlt: `${altText} hotel project building exterior`,
            // 	// ctaText: `Download Case Study`,
            // 	// downloadUrl: '/mehvac_twilight_river_cruises_short.pdf'
            // },
        ],
        backgroundCol: 'gray',
    },
    downloadIra({ color: 'transparent' }),
]

const header = {
    pageTitle: 'HVAC Solutions For Hospitality Applications',
    pageDesc:
        "A better guest experience. Mitsubishi Electric's modular VRF technology features whisper-quiet in-room units, precise temperature control, and infinite scalability, making these systems a superior choice for hotels and resorts.",
    pageImg: mainImg,
    class: 'side-bar dark-mode-header',
    branding: 'metus',
    pageSections: sections.map((section) => section),
    accentCol: 'blue',
    vertical: vertical,
}

export { header, sideBar, hero, sections }

import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react'
import Logo from './modular/Logo'
import Icon from './modular/Icon'
import NavBu from './NavBu'
import ScrollLink from './modular/ScrollLink'
import '../styles/header.css'

export default function Header(props) {
    // set phone number to dynamically change based on UTM campaign id
    // nebu contractor recruitment campaign 7013q000002G8oDAAS --> 855-897-9524
    const [phoneNumber, setPhoneNumber] = useState(props.phone && props.phone)

    const headerClass = props.class ? `header ${props.class}` : 'header'
    const darkMode = headerClass.includes('dark')
    const headerTextColor = darkMode ? 'white' : null

    const utm = new URL(document.location).search

    useEffect(() => {
        if (utm.includes('7013q000002G8oDAAS')) {
            setPhoneNumber('855-897-9524')
        }
    }, [utm])

    const [scrollPos, setScrollPos] = useState(0)

    useEffect(() => {
        // console.log(props.pageImg)
        // When the user scrolls down, reduce header height
        document.addEventListener('scroll', () => {
            setScrollPos(window.scrollY || document.documentElement.scrollTop)
        })
    }, [])

    return (
        <>
            <Helmet>
                <title>{props.pageTitle}</title>
                <meta name="description" content={props.pageDesc} />
                <meta property="og:image" content={props.pageImg} />
                <meta property="twitter:card" content="summary_large_image" />

                {/* DESCRIPTION */}
                <meta name="description" content={props.pageDesc} />
                <meta property="og:description" content={props.pageDesc} />

                {/* TITLE */}
                <meta property="og:title" content={props.pageTitle} />
                <title>{props.pageTitle}</title>
                {/* OneTrust Cookies Consent Notice start */}
                {/* <script type="text/javascript" src="https://cdn.cookielaw.org/consent/59dcd911-968c-458f-b4a4-66e8fe4ab72c/OtAutoBlock.js"></script> */}
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script="59dcd911-968c-458f-b4a4-66e8fe4ab72c"
                ></script>
                {/* <script type="text/javascript">
					function OptanonWrapper() {}
				</script> */}
                {/* OneTrust Cookies Consent Notice end */}
            </Helmet>
            <header id="header" className={headerClass}>
                <ScrollLink to="#top" style={{ display: 'flex', height: '100%' }}>
                    <Logo darkMode={darkMode} branding={props.branding} style={{ cursor: 'pointer' }} />
                </ScrollLink>
                <NavBu
                    color={headerTextColor}
                    id="header-nav"
                    ctaText={props.ctaText}
                    ctaUrl={props.ctaUrl}
                    sections={props.sections}
                    noCta={props.noCta}
                    scrollPos={scrollPos}
                />
                {props.headerTitle && (
                    <div>
                        <h4 style={{ margin: '0' }}>
                            {props.headerTitle && props.headerTitle}
                            {props.symbol && <span className="superscript">{props.symbol}</span>}
                        </h4>
                    </div>
                )}
                {phoneNumber && (
                    <a href={`tel:+1-${phoneNumber}`} className="phone">
                        <Icon id="call" color={headerTextColor} />
                        <div>
                            <div style={{ color: headerTextColor }}>{phoneNumber}</div>
                            {props.hours && <div className="footnote">{props.hours}</div>}
                        </div>
                    </a>
                )}
            </header>
        </>
    )
}
